import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LecabService {
  public urlApiLeCab = 'https://connect.le.cab/public/';

  public client_id = '1236094001';
  public client_secret = '1duJGMx61tvl2wHXYASuYhQJEDPTbBbF';
  public user_id = 'api%2B0216403e-54b1-4302-9ca9-2d2415017612%40lecab.fr';
  public user_password = 'DeU35kcucxL2SvCx';

  constructor( ) { }
}
