import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { AccessProviders } from "./providers/access-providers";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  navigate: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private router: Router,
    private accessProvider: AccessProviders
  ) {
    this.sideMenu();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  sideMenu()
  {
    this.navigate =
    [
      {
        title : 'Mon compte',
        url   : '/account'
      },
      {
        title : 'Mon abonnement',
        url   : '/subscription'
      },
      {
        title : 'Mon trajet',
        url   : '/trajet'
      },
      {
        title : 'Mes économies',
        url   : '/savings'
      },
      {
        title : 'Mon historique',
        url   : '/histories'
      },
      {
        title : 'Mes adresses',
        url   : '/addresses'
      },
      /*{
        title : 'Mes avantages',
        url   : '/advantages'
      },
      {
        title : 'Parrainnage',
        url   : '/sponsorship'
      },*/
      {
        title : 'FAQ',
        url   : '/faq'
      },
      {
        title : 'CGV / CGU',
        url   : '/cgu'
      }
      /*{
        title : 'Stripe JS',
        url   : '/mystripejs'
      }*/
    ];
  }

  public logOut() {
    this.accessProvider.logout()
    this.storage.clear()
    this.router.navigateByUrl('connexion')
  }
}
