import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Storage } from '@ionic/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { UserFirebase } from '../interface/user-firebase';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, map, retry, catchError } from 'rxjs/operators';

@Injectable()
export class AccessProviders {

  // ##########################          VARIABLE             ##############################
  urlServer = 'https://backend.goon.cab/api/';
  urlRecoveryPsw = 'https://backend.goon.cab/resetting/send-email';

  urlLocalServer = 'http://127.0.0.1:8000/api/';

  userToken: string;

  public facebookEmail: string
  public facebookName: string

  private userFirebaseCollection: AngularFirestoreCollection<UserFirebase>;
  userFirebase: Observable<UserFirebase[]>;

  // ##########################          CONSTRUCTEUR             ##############################
  constructor(
    public http: HttpClient,
    private storage: Storage,
    private angularFireAuth: AngularFireAuth,
    private angularFireStore: AngularFirestore,
  ) {
    this.getToken();
    this.angularFireAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.angularFireStore.doc<UserFirebase>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  // ##########################          FONCTION             ##############################

  // this will get called once with constructor execution:
  public getToken() {
    this.storage.get('user').then((token) => {
      if (token) {
        this.userToken = token.data.token
      }
    })
  }

  // Get Request data  No Token
  public getData(file) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    });
    const options = {
      headers: headers
    };
    return this.http.get(this.urlServer + file, options)
      .map(res => res);
  }

  // Get Request data  with Token
  public getDataWithToken(file) {

    if (this.userToken === undefined) {
      this.getToken();

      setTimeout(() => {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);
        const options = {
          headers: headers
        };

        return this.http.get(this.urlServer + file, options).map(res => res);
      });

    } else {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);
      const options = {
        headers: headers
      };

      return this.http.get(this.urlServer + file, options).map(res => res);
    }
  }

  // Post Request data No Token
  public postData(body, file) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    });
    const options = {
      headers: headers
    };



    return this.http.post(this.urlServer + file, JSON.stringify(body), options)
      .map(res => res);
  }

  // Post Request wtith full url
  public postDataStripeUrl(body, file) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': 'Basic c2tfdGVzdF81MUhFWm5ORnlWSzZwNW8zVXo4NHc3VnRGY3hVSEZjWmIyaTZVU1RNMDFQR2w0WE5BT1hYNG1VNzhsWWp6clBqekg2MU95MXQ5NG14ZHRwdUQ4SFlBbW5tMzAwVjhDNHhTRFM6'
    });
    const options = {
      headers: headers
    };
    return this.http.post(this.urlServer + file, JSON.stringify(body), options)
      .map(res => res);
  }

  // Post Request data with token
  public postDataWithToken(body, file) {

    if (this.userToken === undefined) {

      // get token
      this.getToken();
      setTimeout(() => {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);
        const options = {
          headers: headers
        };

        return this.http.post(this.urlServer + file, JSON.stringify(body), options)
          .map(res => res);
      }, 2000);

    } else {
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.userToken);
      const options = {
        headers: headers
      };

      return this.http.post(this.urlServer + file, JSON.stringify(body), options)
        .map(res => res);
    }

  }

  // Function recovery
  public recoveryPassword(body) {
    const params = JSON.stringify(body);
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });
    const options = {
      headers: headers
    };
    return this.http.post(this.urlRecoveryPsw, params, options).map(res => res);
  }

  // firebase
  async logingGoogle() {
    const userGoogle = new auth.GoogleAuthProvider();
    const credentialGoogle = await this.angularFireAuth.signInWithPopup(userGoogle);
    if (credentialGoogle.user.email) {
      this.updateUserFirestore(credentialGoogle.user);
      return [credentialGoogle.user, 'update'];
    } else {
      this.createUserFiretore(credentialGoogle.user);
      return [credentialGoogle.user, 'create'];
    }
  }

  async loginFacebook() {
    const userFacebook = new auth.FacebookAuthProvider();
    const credentialFacebook = await this.angularFireAuth.signInWithPopup(userFacebook);
    this.facebookEmail = credentialFacebook.user.email
    this.facebookName = credentialFacebook.user.displayName
    if (credentialFacebook.user.email) {
      this.updateUserFirestore(credentialFacebook.user)
    } else {
      this.createUserFiretore(credentialFacebook.user);
    }
  }

  public logout() {
    this.angularFireAuth.signOut()
    this.storage.clear()
  }

  createUserFiretore({ uid, email, displayName, phoneNumber }: UserFirebase) {
    this.angularFireStore.collection('users').doc(uid).set({
      uid, email, displayName, phoneNumber
    });
  }

  getDataUserFirestore() {
    return this.angularFireStore.collection('users').valueChanges();
  }

  updateUserFirestore({ uid, email, displayName, phoneNumber }: UserFirebase) {
    const userFirbaseRef: AngularFirestoreDocument<UserFirebase> = this.angularFireStore.doc(`users/${uid}`);
    const infoUserFirebase = {
      uid,
      email,
      displayName,
      phoneNumber
    };
    return userFirbaseRef.set(infoUserFirebase, { merge: true });
  }
}
