import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'cgu',
    loadChildren: () => import('./pages/cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'connexion',
    loadChildren: () => import('./pages/connexion/connexion.module').then( m => m.ConnexionPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'addresses',
    loadChildren: () => import('./pages/addresses/addresses.module').then( m => m.AddressesPageModule)
  },
  {
    path: 'savings',
    loadChildren: () => import('./pages/savings/savings.module').then( m => m.SavingsPageModule)
  },
  {
    path: 'advantages',
    loadChildren: () => import('./pages/advantages/advantages.module').then( m => m.AdvantagesPageModule)
  },
  {
    path: 'sponsorship',
    loadChildren: () => import('./pages/sponsorship/sponsorship.module').then( m => m.SponsorshipPageModule)
  },
  {
    path: 'histories',
    loadChildren: () => import('./pages/histories/histories.module').then( m => m.HistoriesPageModule)
  },
  {
    path: 'forgotten-password',
    loadChildren: () => import('./pages/forgotten-password/forgotten-password.module').then( m => m.ForgottenPasswordPageModule)
  },
  {
    path: 'trajet',
    loadChildren: () => import('./pages/trajet/trajet.module').then( m => m.TrajetPageModule)
  },
  {
    path: 'search-result',
    loadChildren: () => import('./pages/search-result/search-result.module').then( m => m.SearchResultPageModule)
  },
  {
    path: 'offre',
    loadChildren: () => import('./modal/offre/offre.module').then( m => m.OffrePageModule)
  },
  {
    path: 'adresse',
    loadChildren: () => import('./modal/adresse/adresse.module').then( m => m.AdressePageModule)
  },
  {
    path: 'preinscription-valide',
    loadChildren: () => import('./pages/preinscription-valide/preinscription-valide.module').then( m => m.PreinscriptionValidePageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./modal/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'card',
    loadChildren: () => import('./modal/card/card.module').then( m => m.CardPageModule)
  },
  {
    path: 'mystripejs',
    loadChildren: () => import('./pages/mystripejs/mystripejs.module').then( m => m.MystripejsPageModule)
  },
  {
    path: 'mystripenative',
    loadChildren: () => import('./pages/mystripenative/mystripenative.module').then( m => m.MystripenativePageModule)
  },
  {
    path: 'payement-cours',
    loadChildren: () => import('./modal/payement-cours/payement-cours.module').then( m => m.PayementCoursPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
