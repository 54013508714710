import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  NgZone,
  TemplateRef,
} from "@angular/core";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { MapsAPILoader } from "@agm/core";
import {
  NativeGeocoderOptions,
  NativeGeocoder,
  NativeGeocoderResult,
} from "@ionic-native/native-geocoder/ngx";
import { Router } from "@angular/router";
import { AccessProviders } from "../../providers/access-providers";
import { Vehicle } from "../../interface/vehicle";
import { VehicleService } from "src/app/providers/vehicle.service";
import { of } from "rxjs";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { PayementCoursPage } from "src/app/modal/payement-cours/payement-cours.page";
import { ModalController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { AlertController } from "@ionic/angular";
import { LecabService } from "src/app/providers/lecab.service";
import { retry } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const iconStart = {
  url: "./assets/icon/marker-start.png",
  scaledSize: new google.maps.Size(25, 25),
};

const iconScoot = {
  url: "./assets/icon/scooter.png",
  scaledSize: new google.maps.Size(35, 35),
};
const iconMotorscoot = {
  url: "./assets/icon/motorcycle.png",
  scaledSize: new google.maps.Size(35, 35),
};

const iconBike = {
  url: "./assets/icon/bicycle.png",
  scaledSize: new google.maps.Size(35, 35),
};
const iconEnd = {
  url: "./assets/icon/marker-end.png",
  scaledSize: new google.maps.Size(25, 25),
};
const iconTaxi = {
  url: "./assets/icon/taxi.png",
  scaledSize: new google.maps.Size(45, 45),
};
const icon = {
  url: "./assets/icon/marker-icon.png",
  scaledSize: new google.maps.Size(45, 45),
};
let infoWindow = new google.maps.InfoWindow();

@Component({
  selector: "app-google-map",
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"],
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
  @ViewChild("startPoint")
  public startPointElementRef: ElementRef;

  @ViewChild("endPoint")
  public endPointElementRef: ElementRef;

  @ViewChild("bookingPrice") bookingPrice: BsModalRef;
  @ViewChild("bookingTime") bookingTime: BsModalRef;

  @ViewChild("templateConfirm") templateConfirm: BsModalRef;

  mapTrajet: google.maps.Map;
  latitude: number;
  longitude: number;

  public latStartPoint: any;
  public longStartPoint: any;
  public latEndPoint: any;
  public longEndPoint: any;

  public streetStart: any;
  public streetEnd: any;
  public nameStreetStart: any;
  public nameStreetEnd: any;
  public postCodeStart: any;

  address: any;
  zoom: number = 13;
  private geoCoder;
  directionsService = new google.maps.DirectionsService();
  directionRenderer = new google.maps.DirectionsRenderer({
    suppressMarkers: true,
    polylineOptions: { strokeColor: "#FF0365" },
  });

  placeDeparture: string = "";
  placeArrival: string = "";
  distanceRoute: number;

  // GESTION MARKER
  markerInputStart: google.maps.Marker;
  markerInputEnd: google.maps.Marker;
  markerUpdateEnd: google.maps.Marker;
  markerDot: google.maps.Marker;

  vehicleLat: number;
  vehicleLng: number;
  vehicleAgency: string;
  vehiclePrice: number;
  vehicleName: string;
  vehicleAddress: string;
  vehicleEcology: boolean;
  vehicleTimer: any;
  time: any;

  // DISTANCE VEHICULE
  distanceBetween: number[] = [];
  durationVehicle: number;

  // cacl simulation
  totalCostUber: any;
  totalCostLecab: any;
  totalCostKapten: any;

  modalRefPrice: BsModalRef;
  modalRefTime: BsModalRef;
  modalRefConfirm: BsModalRef;
  modalRefBookingPrice: BsModalRef;
  modalRefBookingTime: BsModalRef;

  // Subscribe
  existAbonnement: any;
  subscriptionIdExist: any;
  subscriptionEdit: any;
  useConnected: any;

  // variable incompris => debug seulement
  public desableNext: any;

  public autocompleteStart: any;
  public autocompleteEnd: any;

  public listServiceLecab: any = [];

  public lecabServiceId: any;
  public lecabToken: any;
  public riderId: any;
  public bookingId: any;

  // new list plateform vtc
  public newListVTC = [];
  datas: any;

  constructor(
    private accessProvider: AccessProviders,
    private geolocation: Geolocation,
    private mapApiLoader: MapsAPILoader,
    private ngZone: NgZone,
    private nativeGeocoder: NativeGeocoder,
    private router: Router,
    private storage: Storage,
    private vehicleService: VehicleService,
    private modal: BsModalService,
    public modalController: ModalController,
    private alertController: AlertController,
    private lecab: LecabService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.initializeMap();
    this.existAbonnement = false;
    setTimeout(() => {
      this.existAbonnement = this.checkSubscribe();
    }, 500);
    this.showStorage();
  }

  ngAfterViewInit() {}

  initializeMap() {
    this.mapApiLoader.load().then(() => {
      this.getCurrentPosition();
      this.geoCoder = new google.maps.Geocoder();
      this.autocompleteStart = new google.maps.places.Autocomplete(
        this.startPointElementRef.nativeElement,
        { types: ["geocode"] }
      );
      this.autocompleteStart.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult =
            this.autocompleteStart.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latStartPoint = place.geometry.location.lat();
          this.longStartPoint = place.geometry.location.lng();
          this.zoom = 15;
          const coordsStart = new google.maps.LatLng(
            this.latStartPoint,
            this.longStartPoint
          );

          this.markerInputStart = new google.maps.Marker({
            position: coordsStart,
            icon: iconStart,
            map: this.mapTrajet,
            draggable: true,
            animation: google.maps.Animation.DROP,
          });

          this.markerInputStart.addListener("dragend", () => {
            this.markerInputStart.setMap(null);
          });

          this.getAddress(
            this.latStartPoint,
            this.longStartPoint,
            this.markerInputStart,
            this.mapTrajet
          );
          // this.calcRoute(this.latStartPoint, this.longStartPoint, this.latEndPoint, this.longEndPoint, this.mapTrajet)
          this.directionRenderer.setMap(null);
          this.getAddressPointStart(this.latStartPoint, this.longStartPoint);
        });
      });

      this.autocompleteEnd = new google.maps.places.Autocomplete(
        this.endPointElementRef.nativeElement,
        { types: ["geocode"] }
      );
      this.autocompleteEnd.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult =
            this.autocompleteEnd.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latEndPoint = place.geometry.location.lat();
          this.longEndPoint = place.geometry.location.lng();
          this.zoom = 12;
          const coordsEnd = new google.maps.LatLng(
            this.latEndPoint,
            this.longEndPoint
          );

          this.markerInputEnd = new google.maps.Marker({
            position: coordsEnd,
            icon: iconEnd,
            map: this.mapTrajet,
            draggable: true,
            animation: google.maps.Animation.DROP,
          });

          this.getAddress(
            this.latEndPoint,
            this.longEndPoint,
            this.markerInputEnd,
            this.mapTrajet
          );
          this.calcRoute(
            this.latStartPoint,
            this.longStartPoint,
            this.latEndPoint,
            this.longEndPoint,
            this.mapTrajet
          );
          this.directionRenderer.setMap(this.mapTrajet);

          this.getAddressPointEnd(this.latEndPoint, this.longEndPoint);

          this.markerInputEnd.addListener("dragend", () => {
            this.markerInputEnd.setMap(null);

            let latMarker = this.markerInputEnd.getPosition().lat();
            let lngMarker = this.markerInputEnd.getPosition().lng();

            this.markerUpdateEnd = new google.maps.Marker({
              position: new google.maps.LatLng(latMarker, lngMarker),
              icon: iconEnd,
              map: this.mapTrajet,
              draggable: true,
              animation: google.maps.Animation.DROP,
            });
            this.markerUpdateEnd.setMap(this.mapTrajet);

            this.getAddressPointEnd(latMarker, lngMarker);
            this.getAddress(
              latMarker,
              lngMarker,
              this.markerUpdateEnd,
              this.mapTrajet
            );
            this.calcRoute(
              this.latStartPoint,
              this.longStartPoint,
              latMarker,
              lngMarker,
              this.mapTrajet
            );
            this.directionRenderer.setMap(this.mapTrajet);
          });
        });
      });
    });
  }

  // GET CURRENT POSITION => +++++ Ionic DESK issue +++++ ACCURACY
  public getCurrentPosition() {
    // if ('geolocation' in navigator) {
    let options = {
      timeout: 5000,
      enableHighAccuracy: true,
    };
    this.geolocation.getCurrentPosition(options).then((res) => {
      let accuracy = res.coords.accuracy;
      this.latitude = res.coords.latitude;
      this.longitude = res.coords.longitude;
      const geocoder = new google.maps.Geocoder();
      const coords = new google.maps.LatLng(this.latitude, this.longitude);
      this.mapTrajet = new google.maps.Map(
        document.getElementById("mapTrajet"),
        {
          center: coords,
          zoom: this.zoom,
          disableDoubleClickZoom: true,
          styles: [
            {
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              featureType: "administrative",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "landscape",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "poi",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#dadada",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "water",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#c9c9c9",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
          ],
        }
      );

      // let marker = new google.maps.Marker({
      //     position: coords,
      //     map: this.mapTrajet,
      //     icon: icon,
      //     draggable: true,
      //     animation: google.maps.Animation.DROP
      // })

      // this.getAddress(this.latitude, this.longitude, marker, this.mapTrajet)

      // google.maps.event.addListener(this.mapTrajet, 'click', () => {
      //     let clickMap: google.maps.places.PlaceResult = this.mapTrajet.getPlace()
      //     let latClickMap = clickMap.geometry.location.lat()
      //     let lngClickMap = clickMap.geometry.location.lng()
      //
      // })
    });
  }

  // GET ADRESSE GLOBALE
  getAddress(
    latitude: number,
    longitude: number,
    marker: google.maps.Marker,
    map: google.maps.Map
  ) {
    this.geoCoder.geocode(
      {
        location: { lat: latitude, lng: longitude },
      },
      (res, status) => {
        if (status === "OK") {
          if (res[0]) {
            this.address = res[0].formatted_address;
            this.zoom;

            infoWindow.setPosition(new google.maps.LatLng(latitude, longitude));
            infoWindow.setContent(
              '<div style="background-color:#FF0365; color:white; padding:10px; border-radius: 5px;">' +
                res[0].formatted_address +
                "</div>"
            );
            infoWindow.open(map, marker);

            marker.addListener("click", () => {
              infoWindow.close();
              infoWindow.setPosition(
                new google.maps.LatLng(latitude, longitude)
              );
              infoWindow.setContent(
                '<div style="background-color:#FF0365; color:white; padding:10px; border-radius: 5px;">' +
                  res[0].formatted_address +
                  "</div>"
              );
              infoWindow.open(map, marker);
            });
          } else {
          }
        } else {
        }
      }
    );
  }

  // ADRESSE START
  getAddressPointStart(lat, lng) {
    this.geoCoder.geocode(
      {
        location: { lat: lat, lng: lng },
      },
      (res, status) => {
        if (status === "OK") {
          if (res[0]) {
            this.placeDeparture = res[0].formatted_address;
            this.streetStart =
              res[0].address_components[0].long_name +
              " " +
              res[0].address_components[1].long_name;
            this.nameStreetStart = res[0].address_components[2].long_name;
            this.postCodeStart = res[0].address_components[6].long_name;
            this.getDott(lat, lng);
          }
        }
      }
    );
  }

  getAddressPointEnd(lat, lng) {
    this.geoCoder.geocode(
      {
        location: { lat: lat, lng: lng },
      },
      (res, status) => {
        if (status === "OK") {
          if (res[0]) {
            this.placeArrival = res[0].formatted_address;
            this.streetEnd =
              res[0].address_components[0].long_name +
              " " +
              res[0].address_components[1].long_name;
            this.nameStreetEnd = res[0].address_components[2].long_name;
          }
        }
      }
    );
  }

  // ================================= CALCUL ROUTE MARKER VEHICULE  ===========================================

  // CALCUL ROUTE
  calcRoute(
    latStart: number,
    lngStart: number,
    latEnd: number,
    lngEnd: number,
    map: google.maps.Map
  ) {
    this.directionsService.route(
      {
        origin: new google.maps.LatLng(latStart, lngStart),
        destination: new google.maps.LatLng(latEnd, lngEnd),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (res, status) => {
        if (status == "OK") {
          // POPUP info route
          infoWindow.setContent(
            '<div style="background-color:#FF0365; color:white; padding:10px; border-radius: 5px;">' +
              "Distance : " +
              "<strong>" +
              res.routes[0].legs[0].distance.text +
              "</strong><br>" +
              "Durée : " +
              "<strong>" +
              res.routes[0].legs[0].duration.text +
              "</strong></div>"
          );
          infoWindow.open(map);

          this.distanceRoute = res.routes[0].legs[0].distance.value;
          this.directionRenderer.setDirections(res);

          this.loadVehicleMarker(this.distanceRoute);
        } else {
          let header = "Direction inconnue";
          let message = "Le trajet n' est pas accessible pour les voitures";
          this.alertMessage(header, message);
        }
      }
    );
  }

  calcVechicle(
    latStart: number,
    lngStart: number,
    latVehicle: number,
    lngVehicle: number
  ) {
    this.directionsService.route(
      {
        origin: new google.maps.LatLng(latStart, lngStart),
        destination: new google.maps.LatLng(latVehicle, lngVehicle),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (res: any, status) => {
        if (status === "OK") {
          this.durationVehicle = res.routes[0].legs[0].duration.value;
          this.time = Math.floor(this.durationVehicle / 60);
        }
      }
    );
  }

  // CALCUL ENTRE DEUX POINTS a vol d'oiseau
  calcDistanceFromVehicle(
    latYours: number,
    lngYours: number,
    latVehicle: number,
    lngVehicle: number,
    distance
  ) {
    let origine = new google.maps.LatLng(latYours, lngYours);
    let destination = new google.maps.LatLng(latVehicle, lngVehicle);
    distance = Math.min(
      google.maps.geometry.spherical.computeDistanceBetween(
        origine,
        destination
      )
    );
  }

  // =============================================================================================================

  // LOAD TAXI ON MAP => Create marker
  loadVehicleMarker(distance) {
    this.vehicleService.getVehicle().subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        this.vehicleAgency = res[i].agency;
        this.vehicleLat = res[i].latitude;
        this.vehicleLng = res[i].longitude;
        this.vehicleName = res[i].name;
        this.vehiclePrice = res[i].price;
        this.vehicleEcology = res[i].ecology;
        this.vehicleTimer = res[i].timer;

        this.convertDate(this.vehicleTimer);
        // this.calcDistanceFromVehicle(this.latStartPoint, this.longStartPoint, this.vehicleLat, this.vehicleLng, this.distanceBetween[i])
        this.calcVechicle(
          this.latStartPoint,
          this.longEndPoint,
          this.vehicleLat,
          this.vehicleLng
        );
        this.createMarkerVehicle(
          this.vehicleLat,
          this.vehicleLng,
          this.mapTrajet,
          this.vehicleAgency,
          this.vehiclePrice,
          distance
        );
      }
    });
  }

  // COOVERSION DUREE => Minute
  convertDate(durationValue) {
    let minutes = Math.floor(durationValue / 60);
  }

  // GESTION MARKER TAXI PAR PLATEFORME VTC
  createMarkerVehicle(
    lat: number,
    lng: number,
    map: google.maps.Map,
    agency: string,
    price: number,
    distance: number
  ) {
    let markerVehicle = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      icon: iconTaxi,
      map: map,
      // draggable: true
    });

    // calcul prix par type (en attente api pour paramètre)
    this.calcPriceTrajet(agency, distance, price);

    // calcul distance between
    // this.calcDistanceFromVehicle(this.latStartPoint, this.longStartPoint, lat, lng, this.distanceBetween)

    markerVehicle.addListener("click", () => {
      infoWindow.setPosition(new google.maps.LatLng(lat, lng));

      if (agency === "Uber") {
        infoWindow.setContent(
          '<div style="background-color:#FF0365; color:white; padding:10px; border-radius: 5px;"><img src="assets/icon/uber-info.jpg"><br>' +
            "Montant de base : " +
            price +
            " €" +
            "<br>" +
            "Montant course : " +
            "<strong>" +
            this.totalCostUber +
            " €" +
            "</strong></div>"
        );
      } else if (agency === "Kapten") {
        infoWindow.setContent(
          '<div style="background-color:#FF0365; color:white; padding:10px; border-radius: 5px;"><img src="assets/icon/kapten-info.png"><br>' +
            "Montant de base : " +
            price +
            " €" +
            "<br>" +
            "Montant course : " +
            "<strong>" +
            this.totalCostKapten +
            " €" +
            "</strong></div>"
        );
      } else if (agency === "LeCab") {
        infoWindow.setContent(
          '<div style="background-color:#FF0365; color:white; padding:10px; border-radius: 5px;"><img src="assets/icon/lecab-info.png"><br>' +
            "Montant de base : " +
            price +
            " €" +
            "<br>" +
            "Montant course : " +
            "<strong>" +
            this.totalCostLecab +
            " €" +
            "</strong></div>"
        );
      }
      infoWindow.open(map, markerVehicle);
    });
  }

  // CALCUL COURSE TAXE (en attente api pour paramètre)
  async calcPriceTrajet(agency: string, distance: number, price: number) {
    if (agency === "Uber") {
      this.totalCostUber = ((distance / 1000) * price).toFixed(1);
    }
    if (agency === "LeCab") {
      this.totalCostLecab = ((distance / 1000) * price).toFixed(1);
    }
    if (agency === "Kapten") {
      this.totalCostKapten = ((distance / 1000) * price).toFixed(1);
    } else {
      return of(null);
    }
  }

  // PUSH TRAJET TO HISTORIQUE
  public trajetToHistorique() {
    this.storage.set("trajetAdd", {
      placeDeparture: this.placeDeparture,
      placeArrival: this.placeArrival,
    });
  }

  // ======================================= LACAB FUNCTION ==============================================

  // BTN Suivant => MODAL PLATEFORME

  /**
   * getLecabTokent
   */
  public getLecabTokent() {
    this.storage.get("tokenLecab").then((res: any) => {
      this.lecabToken = res;
    });
  }

  // get service LECAB
  public getServiceVTC() {
    //  NE PAS EFFACER POURRAIT ETRE UTILE
    // this.storage.get('tokenLecab').then((res: any) => {
    //   this.lecabToken = res
    //   let urlLecab = this.lecab.urlApiLeCab
    //   let requestOptions: any = {
    //     method: 'GET',
    //     redirect: 'follow'
    //   }
    //   fetch(
    //     urlLecab + 'info/service_classes?' +
    //     'token=' + this.lecabToken,
    //     requestOptions
    //   ).then((res: any) => res.json()).then((r: any) => {
    //     this.listServiceLecab = r
    //     this.getUserLecab()
    //   })
    // })
    // this.modalModalServiceLecab = this.modal.show(this.serviceLecab)
  }

  /**
   * getUserLecab
   */
  public getUserLecab() {
    this.storage.get("userLecab").then((res: any) => {
      this.riderId = res.rider_id;
    });
  }

  public getMyServiceLecab(serviceId) {
    this.lecabServiceId = serviceId;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("start_location[lat]", this.longStartPoint);
    urlencoded.append("start_location[lng]", this.latStartPoint);
    urlencoded.append("start_location[address][name]", this.streetStart);
    urlencoded.append("start_location[address][city]", this.nameStreetStart);
    urlencoded.append(
      "start_location[address][postal_code]",
      this.postCodeStart
    );
    urlencoded.append("rider_id", this.riderId);
    urlencoded.append("service_class_id", serviceId);
    urlencoded.append("pro", "1");

    var requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      this.lecab.urlApiLeCab + "bookings?" + "token=" + this.lecabToken,
      requestOptions
    )
      .then((response) => response.json())
      .then((result: any) => {
        if ((result.message = "dispatch_group_forbidden")) {
          this.alertMessage("Message lecab", "dispatch_group_forbidden");
          console.log(result);
        } else {
          console.log(result);
        }
      })
      .catch((error) => console.log("error", error));
  }

  // STATIC DATA FOR PRICE
  async loadVehicleModalPrice() {
    this.vehicleService.getVehicle().subscribe((res: any) => {
      console.log("STATIC PRICE =", res);
      res.map((dataStatic) => {
        let theTruePrice: any;
        if (dataStatic.agency == "Uber") {
          theTruePrice = Number(this.totalCostUber);
        }
        if (dataStatic.agency == "Kapten") {
          theTruePrice = Number(this.totalCostKapten);
        }

        let objetStatic: any = {
          id: dataStatic.id,
          agency: dataStatic.agency,
          price: theTruePrice,
          timer: dataStatic.timer,
          type: dataStatic.type,
          free: false,
        };
        this.newListVTC.push(objetStatic);
      });
      this.filterByPrice(this.newListVTC);
    });
  }

  // STATIC DATA FOR TIME
  async loadVehicleModalTime() {
    this.vehicleService.getVehicle().subscribe((res: any) => {
      console.log("STATIC PRICE =", res);
      res.map((dataStatic) => {
        let theTruePrice: any;
        if (dataStatic.agency == "Uber") {
          theTruePrice = Number(this.totalCostUber);
        }
        if (dataStatic.agency == "Kapten") {
          theTruePrice = Number(this.totalCostKapten);
        }

        let objetStatic: any = {
          id: dataStatic.id,
          agency: dataStatic.agency,
          price: theTruePrice,
          timer: dataStatic.timer,
          type: dataStatic.type,
          free: false,
        };
        this.newListVTC.push(objetStatic);
      });
      this.filterByTime(this.newListVTC);
    });
  }

  /**
   * getBookingPrice
   */
  public async getBookingPrice() {
    this.newListVTC = [];
    if (this.modalRefBookingTime) {
      this.modalRefBookingTime.hide();
    }

    await (<any>this.getUserLecab());
    if (this.placeDeparture == "" || this.placeArrival == "") {
      let header = "Erreur destination";
      let message = "Veuillez remplir vos points de départ et d'arrivée !";
      this.alertMessage(header, message);
    } else {
      this.storage.get("tokenLecab").then((res) => {
        this.lecabToken = res;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();

        urlencoded.append("rider_id", this.riderId);
        urlencoded.append("start_location[lat]", this.latStartPoint);
        urlencoded.append("start_location[lng]", this.longStartPoint);
        urlencoded.append("start_location[address][name]", this.streetStart);
        urlencoded.append(
          "start_location[address][city]",
          this.nameStreetStart
        );
        urlencoded.append("end_location[lat]", this.latEndPoint);
        urlencoded.append("end_location[lng]", this.longEndPoint);
        urlencoded.append("end_location[address][name]", this.streetEnd);
        urlencoded.append("end_location[address][city]", this.nameStreetEnd);
        urlencoded.append("pro", "1");

        var requestOptions: any = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        fetch(
          this.lecab.urlApiLeCab +
            "bookings/prices?" +
            "token=" +
            this.lecabToken,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (result: any) => {
            if (result.message) {
              let message: "Veuillez vous reconnecter...";
              this.alertMessage("Reconnexion", message);
            } else {
              console.log(result);
              const myList = result.map((res) => {
                // let time = Math.floor(Math.random() * 6) + 1
                let time = 7;
                let objectLecab: any = {
                  id: res.id,
                  agency: "leCab",
                  price: res.price,
                  timer: time,
                  type: res.service_class.name.fr,
                  free: true,
                };
                this.newListVTC.push(objectLecab);
              });

              this.loadVehicleModalPrice();

              await this.filterByPrice(this.newListVTC);
              this.modalRefBookingPrice = this.modal.show(this.bookingPrice);
            }
          })
          .catch((error) => console.log("error", error));
      });
    }
  }

  /**
   * getMyBookingTime
   */
  public async getMyBookingTime() {
    this.newListVTC = [];
    if (this.modalRefBookingPrice) {
      this.modalRefBookingPrice.hide();
    }

    await (<any>this.getUserLecab());
    if (this.placeDeparture == "" || this.placeArrival == "") {
      let header = "Erreur destination";
      let message = "Veuillez remplir vos points de départ et d'arrivée !";
      this.alertMessage(header, message);
    } else {
      this.storage.get("tokenLecab").then((res) => {
        this.lecabToken = res;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();

        urlencoded.append("rider_id", this.riderId);
        urlencoded.append("start_location[lat]", this.latStartPoint);
        urlencoded.append("start_location[lng]", this.longStartPoint);
        urlencoded.append("start_location[address][name]", this.streetStart);
        urlencoded.append(
          "start_location[address][city]",
          this.nameStreetStart
        );
        urlencoded.append("end_location[lat]", this.latEndPoint);
        urlencoded.append("end_location[lng]", this.longEndPoint);
        urlencoded.append("end_location[address][name]", this.streetEnd);
        urlencoded.append("end_location[address][city]", this.nameStreetEnd);
        urlencoded.append("pro", "1");

        var requestOptions: any = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        fetch(
          this.lecab.urlApiLeCab +
            "bookings/prices?" +
            "token=" +
            this.lecabToken,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (result: any) => {
            if (result.message) {
              let message: "Veuillez vous reconnecter...";
              this.alertMessage("Reconnexion", message);
            } else {
              console.log(result);
              const myList = result.map((res) => {
                // let time = Math.floor(Math.random() * 6) + 1
                let time = 7;
                let objectLecab: any = {
                  id: res.id,
                  agency: "leCab",
                  price: res.price,
                  timer: time,
                  type: res.service_class.name.fr,
                  free: true,
                };
                this.newListVTC.push(objectLecab);
              });

              this.loadVehicleModalTime();

              await this.filterByTime(this.newListVTC);
              this.modalRefBookingTime = this.modal.show(this.bookingTime);
            }
          })
          .catch((error) => console.log("error", error));
      });
    }
  }

  /**
   * selectMyBooking
   */
  public selectMyBooking(param: any) {
    if (param.free == false) {
      let message = "";
      this.alertMessage("Voiture indisponible", message);
      this.modalRefBookingPrice.hide();
    } else {
      this.bookingId = param.id;
      var requestOptions: any = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        this.lecab.urlApiLeCab +
          "bookings/prices/" +
          this.bookingId +
          "/confirm?token=" +
          this.lecabToken,
        requestOptions
      )
        .then((response) => response.json())
        .then((result: any) => {
          console.log(result);

          this.modalRefConfirm = this.modal.show(this.templateConfirm, {
            class: "modal-sm",
          });
          this.storage.set("ReservationPay", result);
        })
        .catch((error) => console.log("error", error));
    }
  }

  // ORDER BY ++++++++++++++++++

  public filterByPrice(liste) {
    this.mySortPriceRecursive(liste, liste.length);
  }

  public mySortPriceRecursive(arr, n) {
    if (n <= 1) {
      return;
    }
    this.mySortPriceRecursive(arr, n - 1);
    const last = arr[n - 1];
    let j = n - 2;
    while (j >= 0 && arr[j].price > last.price) {
      arr[j + 1] = arr[j];
      j--;
    }
    arr[j + 1] = last;
  }

  // TIME FILTER
  public filterByTime(liste) {
    this.mySortRecursive(liste, liste.length);
  }

  public mySortRecursive(arr, n) {
    if (n <= 1) {
      return;
    }
    this.mySortRecursive(arr, n - 1);
    const last = arr[n - 1];
    let j = n - 2;
    while (j >= 0 && arr[j].price > last.timer) {
      arr[j + 1] = arr[j];
      j--;
    }
    arr[j + 1] = last;
  }

  // =============================================================================================

  // CONFIRAMTION RESERVATION
  public confirmMyBooking(): void {
    try {
      this.modalRefConfirm.hide();
      this.modalRefBookingPrice.hide();
    } catch (err) {}

    this.trajetToHistorique();

    if (this.existAbonnement == true) {
      this.showPayement();
    }
    if (this.existAbonnement == false) {
      // Route Vers subscription
      this.storage.set("trajetPage", "true"); // if affiche showPayement
      this.router.navigateByUrl("subscription");
    }
  }

  // DECLINER LA RESERVATION
  public declineMyBooking(): void {
    this.storage.remove("ReservationPay"); // A traiter dans payement cours
    // localStorage.removeItem('myBooking')
    this.modalRefConfirm.hide();
  }

  // show STRIPE payement
  public async showPayement() {
    const modal = await this.modalController.create({
      component: PayementCoursPage,
      cssClass: "modal-payementCours-class",
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
  }

  // Get exist abonnement
  public checkSubscribe(): boolean {
    // parametre user_id
    this.storage.get("user").then((val) => {
      this.accessProvider
        .getDataWithToken("subscription/getByActifSubId/" + val?.data.id)
        .subscribe((res: any) => {
          if (res.data.length !== 0) {
            this.existAbonnement = true;

            this.storage.set("typeAction", "modification");
            this.storage.set("subscriptionSelected", res.data[0].id);

            this.getSpecificSubscriptions(res.data[0].id);
            this.subscriptionIdExist = res.data[0].id;
          } else {
            this.existAbonnement = false;
            this.storage.set("typeAction", "ajout");
            this.storage.set("subscriptionSelected", 0); // puisqu'il n'y a pas d'edition
          }
        });
    });
    return true;
  }

  /*
   *  Function find specific ID
   */
  public getSpecificSubscriptions(subscriptionId) {
    this.accessProvider
      .getDataWithToken("subscription/getById/" + subscriptionId)
      .subscribe((res: any) => {
        // Affecation data via API;
        this.subscriptionEdit = res.data[0];

        if (res.data[0].date_fin !== null) {
          this.subscriptionEdit.date_deb = new Date(res.data[0].date_deb.date);
        }

        if (res.data[0].date_fin !== null) {
          this.subscriptionEdit.date_fin = new Date(res.data[0].date_fin.date);
        }

        if (res.data[0].date_suspension !== null) {
          this.subscriptionEdit.date_suspension = new Date(
            res.data[0].date_suspension.date
          );
        }

        // traitement de clé etrangère
        this.subscriptionEdit.mois = res.data[0].offre_mois;
        this.subscriptionEdit.maxCoursRemis = res.data[0].offre_maxCoursRemis;
        this.subscriptionEdit.pourRemisCours = res.data[0].offre_pourRemisCours;
        this.subscriptionEdit.prixSeuilMax = res.data[0].offre_prixSeuilMax;
        this.subscriptionEdit.prixttc = res.data[0].offre_prixttc;
        this.subscriptionEdit.price = res.data[0].offre_price;

        this.subscriptionEdit.categorieId = res.data[0].categorie_id;
        this.subscriptionEdit.offreId = res.data[0].offre_id;
        this.subscriptionEdit.userId = res.data[0].user_id;

        this.subscriptionEdit.suspension = res.data[0].suspension;

        this.storage.set("courseRemise", {
          remise: res.data[0].offre_pourRemisCours,
          categorie: res.data[0].categorie_id,
        });

        if (this.subscriptionEdit.suspension > 0) {
          this.storage.set("etatSuspension", { suspendu: true });
        } else {
          this.storage.set("etatSuspension", { suspendu: false });
        }
      });
  }

  /*
   *Get local storage variable |  obtention des variables traiter
   */
  public showStorage() {
    this.storage.get("user").then((res) => {
      this.useConnected = res;
      this.accessProvider
        .getDataWithToken("subscription/getByActifSubId/" + res.data.id)
        .subscribe((res: any) => {
          if (res.data.length !== 0) {
            this.existAbonnement = true;
            this.storage.set("typeAction", "modification");
            this.storage.set("subscriptionSelected", res.data[0].id);
            this.getSpecificSubscriptions(res.data[0].id);
            this.subscriptionIdExist = res.data[0].id;
          } else {
            this.existAbonnement = false;
            this.storage.set("typeAction", "ajout");
            this.storage.set("subscriptionSelected", 0); // puisqu'il n'y a pas d'edition
          }
        });
    });
  }

  // CLEAR TRAJET
  public resetTrajet() {
    this.directionRenderer.setMap(null);
    this.markerInputStart.setMap(null);
    this.markerInputEnd.setMap(null);
    this.markerUpdateEnd.setMap(null);
    this.storage.clear();
    this.placeDeparture = this.placeArrival = "";
    google.maps.event.clearInstanceListeners(this.autocompleteStart);
    google.maps.event.clearInstanceListeners(this.autocompleteEnd);
  }

  // =============== GESTION ALERT ++++++++++++++++++++++++

  // Alert message
  public async alertMessage(header, message) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      cssClass: "modal-alert",
      buttons: [
        {
          text: "OK",
        },
      ],
    });
    return alert.present();
  }
  async getDott(lat, lng) {
    console.log("test", lat, lng);
    const url =
      "https://flow-api.fluctuo.com/v1?access_token=714dDASAKkHTloMrO4GyOE7Q96bjkHHc";
    const body = {
      query:
        "query ($lat: Float!, $lng: Float!) {\n  vehicles(lat: $lat, lng: $lng) {\n\t\tid\n,\n\t\ttype\n,attributes,lat,lng,provider{name}, propulsion, battery}\n}",
      variables: { lat: lat, lng: lng },
    };
    const headers = new HttpHeaders().set("accept", "application/json");
    this.http.post<any>(url, body, { headers: headers }).subscribe((res) => {
      this.datas = [];
      const result = res.data.vehicles.filter((obj) => {
        return (
          obj.type === "MOTORSCOOTER" ||
          obj.type === "BIKE" ||
          obj.type === "SCOOTER"
        );
      });
      for (let i = 0; i < result.length; i++) {
        const coordsStart = new google.maps.LatLng(
          result[i].lat,
          result[i].lng
        );
        if (result[i].type === "MOTORSCOOTER") {
          this.markerDot = new google.maps.Marker({
            position: coordsStart,
            map: this.mapTrajet,
            icon: iconMotorscoot,
            animation: google.maps.Animation.DROP,
          });
          this.markerDot.addListener("click", () => {
            infoWindow.setContent(
              '<div style="background-color:#FF0365; color:white; padding:10px; border-radius: 5px;">' +
                "Nom service: " +
                "<strong>" +
                result[i].provider.name +
                "</strong><br>" +
                "type: " +
                "<strong>" +
                "Scooteur" +
                "</strong><br>" +
                "propulsion : " +
                "<strong>" +
                result[i].propulsion +
                "</strong><br>" +
                "Batterie : " +
                "<strong>" +
                result[i].battery +
                "</strong>" +
                "</div>"
            );
            infoWindow.setPosition(
              new google.maps.LatLng(result[i].lat, result[i].lng)
            );
          });
        } else if (result[i].type === "BIKE") {
          this.markerDot = new google.maps.Marker({
            position: coordsStart,
            map: this.mapTrajet,
            icon: iconBike,
            animation: google.maps.Animation.DROP,
          });
          this.markerDot.addListener("click", () => {
            infoWindow.setContent(
              '<div style="background-color:#41931c; color:white; padding:10px; border-radius: 5px;">' +
                "Nom service: " +
                "<strong>" +
                result[i].provider.name +
                "</strong><br>" +
                "type: " +
                "<strong>" +
                "Vélo" +
                "</strong><br>" +
                "propulsion : " +
                "<strong>" +
                result[i].propulsion +
                "</strong><br>" +
                "Batterie : " +
                "<strong>" +
                result[i].battery +
                "</strong>" +
                "</div>"
            );
            infoWindow.setPosition(
              new google.maps.LatLng(result[i].lat, result[i].lng)
            );
          });
        } else if (result[i].type === "SCOOTER") {
          this.markerDot = new google.maps.Marker({
            position: coordsStart,
            map: this.mapTrajet,
            icon: iconScoot,
            animation: google.maps.Animation.DROP,
            optimized: true,
          });
          this.markerDot.addListener("click", () => {
            infoWindow.setContent(
              '<div style="background-color:#3f8afe; color:white; padding:10px; border-radius: 5px;">' +
                "Nom service: " +
                "<strong>" +
                result[i].provider.name +
                "</strong><br>" +
                "type: " +
                "<strong>" +
                "Trottinette" +
                "</strong><br>" +
                "propulsion : " +
                "<strong>" +
                result[i].propulsion +
                "</strong><br>" +
                "Batterie : " +
                "<strong>" +
                result[i].battery +
                "</strong>" +
                "</div>"
            );
            infoWindow.setPosition(
              new google.maps.LatLng(result[i].lat, result[i].lng)
            );
          });
        }
        console.log("test", this.markerDot);
      }
    });
  }
}
