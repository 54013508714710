import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AccessProviders } from './providers/access-providers';
import { IonicStorageModule } from '@ionic/storage';
import { ComponentsModule } from './components/components.module';

// image
import { ImagePicker } from '@ionic-native/image-picker/ngx';

// map
import { AgmCoreModule } from '@agm/core';

// firebase auth
import { environment } from './../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID } from '@angular/core';
registerLocaleData(localeFr, 'fr');
import { VehicleService } from "./providers/vehicle.service";
import { NgPipesModule } from 'ngx-pipes';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { DatePipe } from "@angular/common";

@NgModule({
  declarations: [
      AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    NgPipesModule,
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyC8FM1bRLB9ukslZleFqWfluVmw299Xd5A',
        libraries: ['places']
    }),
    IonicStorageModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ModalModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    BrowserAnimationsModule
  ],
  providers: [
    Storage,
    Facebook,
    GooglePlus,
    StatusBar,
    SplashScreen,
    AccessProviders,
    ImagePicker,
    DatePipe,
    VehicleService,
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
