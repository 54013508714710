import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  urlApiVehicle = '../../assets/data/vehicle.json'

  constructor(private httpClient: HttpClient) { }

  getVehicle() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8'
    })
    const options = {
      headers: headers
    }
    return this.httpClient.get(this.urlApiVehicle, options)
      .map(res => res)
  }
}
