import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { AccessProviders } from 'src/app/providers/access-providers';
import { Storage } from '@ionic/storage';
import { of } from 'rxjs';

declare var Stripe;

@Component({
  selector: 'app-payement-cours',
  templateUrl: './payement-cours.page.html',
  styleUrls: ['./payement-cours.page.scss'],
})
export class PayementCoursPage implements OnInit {

  // Public key from front 
  stripe = Stripe('pk_test_51HEZnNFyVK6p5o3UKxz8LSRYbspTzMtBgFpRGOmyLqlbwak9mtbLnVVEJaWf8gbJFT4UA7yUmi44rOLXTwAbDo6900yKj5no6L');
  card: any

  m_user_id: number

  category: number
  remise: number
  saving: any

  etatSuspension: boolean = false

  public bookingId: any
  public priceAgency: number
  public placeDeparture: any
  public placeArrival: any

  public price: number
  public payementCourse: boolean = false

  public agency: any // à déterminer selon évolution

  constructor(
    private accessProvider: AccessProviders,
    private storage: Storage,
    public viewPageCtrl: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController
  ) {
    this.price = 0;
    this.priceAgency = 0;
  }

  ngOnInit() {
    this.m_user_id = 1
    this.checkBooking()

    // Load Tamplate for card 
    setTimeout(() => {
      this.setupStripe()
    }, 2000)

    this.storage.get('user').then((result) => {
      if (result !== null) {
        this.m_user_id = result.data.id;
      }
    })
  }

  // Fermeture du modal
  public dismiss() {
    this.viewPageCtrl.dismiss()
  }

  public async setupStripe() {

    const elements = this.stripe.elements();
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    const card = elements.create('card', { hidePostalCode: true, style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');

    card.addEventListener('change', event => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    })

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault()

      this.storage.get('courseRemise')
        .then((res) => {
          if (res) {
            this.category = res.categorie
            this.remise = res.remise

            this.saving = ((this.priceAgency * this.remise) / 100).toFixed(2)

            if (this.category === 2 || this.category === 3) {
              this.storage.get('etatSuspension')
                .then((res) => {
                  this.etatSuspension = res.suspendu

                  if (this.etatSuspension === true) {
                    this.storage.get('debutSuspension')
                      .then((r) => {
                        let dateDebut = r.dateDebut
                        let dateFin = r.dateFin
                        let dateNow = new Date()
                        if (dateDebut.getTime() > dateNow.getTime() || dateFin.getTime() < dateNow.getTime()) {
                          setTimeout(() => {
                            const body3 = {
                              id_sav: this.getRandom(9),
                              price: this.saving,
                              user_id: this.m_user_id
                            }

                            this.accessProvider.postDataWithToken(body3, 'savings/createSavings')
                              .subscribe(() => { })
                          }, 2000)
                        }
                      })
                  }

                  if (res.length === 0 || this.etatSuspension === false) {

                    const body3 = {
                      id_sav: this.getRandom(9),
                      price: this.saving,
                      user_id: this.m_user_id
                    };

                    this.accessProvider.postDataWithToken(body3, 'savings/createSavings')
                      .subscribe(() => { })
                  }
                })
              // ajout economie 

            } else {
              return of(null)
            }
          }
        })

      // Token to create payement 
      this.stripe.createToken(card).then((mytoken) => {

        this.loadingPayement()

        const body1 = {
          token: mytoken.token.id,
          amount: this.priceAgency * 100,
          currency: "eur",
          description: "Paiement course Taxi"
        }

        this.accessProvider.postDataStripeUrl(body1, 'stripe/createPayementIntent')
          .subscribe(result => {
            if (result) {

              const today = new Date()
              this.storage.remove("ReservationPay")
              this.storage.remove("trajetPage")

              // enregistrement des historique / trajet
              this.payementCourse = true;

              if (this.payementCourse === true) {
                setTimeout(() => {
                  let header = 'Réservation reçue'
                  let message = 'Votre réservation a été prise en compte.' + '<br>'
                    + 'Départ : ' + `${this.placeDeparture}` + '</br>'
                    + 'Arrivée : ' + `${this.placeArrival}` + '</br>'
                    + 'Montant : ' + `${this.priceAgency}` + ' €' + '</br>'
                    + 'Merci d\'avoir utilisé notre service!';
                  this.alertMessage(header, message);
                }, 4000);
              } else {
                setTimeout(() => {
                  let header = 'Erreur'
                  let message = 'Vérifiez la série de votre carte'
                  this.alertMessage(header, message)
                }, 4000)
              }

              this.storage.get("trajetAdd").then((result) => {

                if (result) {
                  return new Promise(() => {
                    let body = {
                      placeDeparture: result.placeDeparture,
                      placeArrival: result.placeArrival
                    }
                    this.accessProvider.postDataWithToken(body, 'trajet/createTrajet').subscribe((res: any) => {

                      //  this.router.navigate(['/trajet']);
                      setTimeout(() => {
                        this.viewPageCtrl.dismiss();
                        this.payementCourse = false;
                      }, 1000)

                    })
                  })
                }
              })

            }
          }, (err) => {

            let headerError = 'Réservatin interrompue'
            let messageError = 'Une erreur est survenue lors de la validation.' + '<br>' + 'Veuillez réessayer...'
            this.alertMessage(headerError, messageError)
          });

      });

      // Resource created by plugin 
      this.stripe.createSource(card).then(result => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
        }
      })
    })
  }


  // Verification si reservation en cours exit
  public async checkBooking() {
    await this.storage.get('ReservationPay').then((result: any) => {
      if (result !== null) {
        // this.bookingId = result.id
        this.placeArrival = result?.start_location?.address?.name
        this.placeDeparture = result?.end_location?.address?.name
        this.priceAgency = result?.booking_price?.price
      }
    })
  }

  public getRandom(length) {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1))
  }

  public async loadingPayement() {
    const alert = await this.loadingController.create({
      message: 'Nous procédons à la validation de votre réservation',
      duration: 4000,
      cssClass: 'alert-modal',
      spinner: "bubbles"
    })
    return alert.present()
  }

  public async alertMessage(header, message) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [{
        text: 'OK'
      }]
    })
    return alert.present()
  }
}