// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // firebase
  firebaseConfig : {
    apiKey: "AIzaSyC4PpBFkrEt47iu_6pF8Z_nPwXU7CJqv8Y",
    authDomain: "goon-01011919.firebaseapp.com",
    databaseURL: "https://goon-01011919.firebaseio.com",
    projectId: "goon-01011919",
    storageBucket: "goon-01011919.appspot.com",
    messagingSenderId: "571178282688",
    appId: "1:571178282688:web:365b08640a4ee97c9bc725",
    measurementId: "G-YH2G2JRWV3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
